export default {
  "login": {
    "enterCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the access code"])},
    "enterCodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To connect with your device, please enter the access code displayed in the mobile app."])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "downloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the mobile app"])}
  },
  "loading": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "loadingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait, connecting to your device."])}
  },
  "dashboard": {
    "filesNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, we didn't find any files matching your search criteria."])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "fileTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload date"])},
    "addNewFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add new file"])},
    "allFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All files"])},
    "recentFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent files"])},
    "recentFilesDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files from the last 30 days"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
    "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "soundFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound Files"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for anything"])},
    "errorModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong  :("])},
    "errorModalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem with connection with the mobile app. Please try again later."])},
    "errorPasscodeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect passcode  :("])},
    "errorPasscodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check passcode if have 6 digits and provide correct input. Please, try again."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])},
    "filesMoreThan10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "areYouSureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure? Deleting "])},
    "areYouSureTextContinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will be permanently removed from your inventory."])},
    "selectedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selected"])},
    "disconnectAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect account"])},
    "disconnectAreYouSureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to proceed?"])},
    "deleteFileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete file"])},
    "deleteAreYouSureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure? Deleting a file will permanently remove it from your inventory."])},
    "uploadModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file can't be uploaded"])},
    "invalidInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid input"])},
    "areNotRecentFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No files uploaded in the last 30 days were found."])},
    "areNotRecentFilesSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may not have uploaded any files during this period or they might have been deleted."])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file"])},
    "noItemsHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items here!"])},
    "dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag & drop your file here to start uploading"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- or -"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "notFound": {
    "ops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ooops!"])},
    "isNotHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for is not here."])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again."])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])}
  },
  "cloudOnMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud On Mobile"])}
}