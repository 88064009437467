export default {
  "login": {
    "enterCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod dostępu"])},
    "enterCodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby połączyć się z urządzeniem, wprowadź kod dostępu wyświetlony w Twojej aplikacji mobilnej."])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz"])},
    "downloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ściągnij aplikację mobilną na Twój telefon"])}
  },
  "loading": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie..."])},
    "loadingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę czekać, trwa łączenie z Twoim urządzeniem."])}
  },
  "dashboard": {
    "filesNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uups, nie znaleźliśmy pliku zgodnego z Twoim wyszukiwaniem."])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmiar"])},
    "fileTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dodania"])},
    "addNewFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Dodaj nowy plik"])},
    "allFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie pliki"])},
    "recentFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie pliki"])},
    "recentFilesDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pliki z ostatnich 30 dni"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj"])},
    "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrazy"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pliki"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wideo"])},
    "soundFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzyka"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj..."])},
    "errorModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś poszło nie tak :("])},
    "errorModalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił problem w trakcie połączenia z Twoim urządzeniem. Spróbuj ponownie później"])},
    "errorPasscodeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błedny kod dostępu :("])},
    "errorPasscodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź poprawny kod dostępu z Twojej aplikacji mobilnej."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki wyszukiwania"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plik"])},
    "filesMoreThan10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plików"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
    "areYouSureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteś pewien? "])},
    "areYouSureTextContinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["będą trwale usunięte z Twojej aplikacji."])},
    "selectedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaznaczono"])},
    "disconnectAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj"])},
    "disconnectAreYouSureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno chcesz się wylogować?"])},
    "deleteFileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń plik"])},
    "deleteAreYouSureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno chcesz trwale usunąć plik z Twojego urządzenia?"])},
    "uploadModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można załadować tego elementu."])},
    "invalidInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
    "areNotRecentFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych plików dodanych w ciągu ostatnich 30 dni."])},
    "areNotRecentFilesSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możliwe, że nie dodałeś jeszcze żadnych plików w tym okresie lub zostały one usunięte."])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz plik"])},
    "noItemsHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak plików!"])},
    "dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeciągnij plik na te pole jeśli chcesz zacząć przesyłać pliki"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- lub -"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])}
  },
  "notFound": {
    "ops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuups!"])},
    "isNotHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona, której szukasz nie istnieje"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj jeszcze raz."])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do strony głównej"])}
  },
  "cloudOnMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud On Mobile"])}
}